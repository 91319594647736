.motionBackground {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  @media (min-width: 768px) {
    display: none !important;
  }
}
